export default ({ app: _app }, inject) => {
  const breadcrumbsItems = []

  // パンくずリストの設定
  // if (app.context.route.name === 'reports-:targetType-create') {
  //   breadcrumbsItems = [
  //     {
  //       text: 'TOP',
  //       disabled: false,
  //       href: '/',
  //     },
  //     {
  //       text: '対象事例の登録',
  //       disabled: true,
  //       href: '/',
  //     },
  //   ]
  // } else if (app.context.route.name === 'reports-:id-stories-check') {
  //   breadcrumbsItems = [
  //     {
  //       text: 'TOP',
  //       disabled: false,
  //       href: '/',
  //     },
  //     // {
  //     //   text: '',
  //     //   disabled: false,
  //     //   href: 'breadcrumbs_link_1',
  //     // },
  //     {
  //       text: '成約事例登録有無の確認',
  //       disabled: true,
  //       href: '',
  //     },
  //   ]
  // }
  inject('breadcrumbsItems', breadcrumbsItems)
}
