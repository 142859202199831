/* eslint-disable no-empty-pattern */
export default ({$api}, inject) => {
  inject('geocode', async (address) => {
    if (address !== '') {
      try {
        const res = await $api.geocodeLocation.geocodeLocation({
          address
        })
        
        return res.data.location
      } catch (error) {
        return null // 位置情報が見つからない場合はnullを返す
      }
    }
  })
}
