// MEMO
// もしhowmaBaRequestIdがセッションストレージに保存されていた場合、
// 反響情報を取得して、概要入力画面にリダイレクトするミドルウェアです。
export default async ({ $api, $auth, redirect }) => {
  if ($auth.loggedIn) {
    const howmaBaRequestId = sessionStorage.getItem('howmaBaRequestId') ?? ''

    if (howmaBaRequestId) {
      sessionStorage.removeItem('howmaBaRequestId')

      // HowMaの反響情報を取得
      const { data } = await $api.howma.howmaBaRequestDetail(howmaBaRequestId)

      if (data) {
        // すでに査定書が作成済みであれば査定書サマリにリダイレクトする。
        if (data.report_id) {
          if (
            data.real_estate_type === 'condo' &&
            data.report.condo.room_current_state === 'rent'
          ) {
            redirect(`/condo-income-reports/${data.report_id}`)
          } else {
            redirect(`/reports/${data.report_id}`)
          }
          return
        }

        // session storageに保存
        // この情報は概要入力の画面で使用する。
        sessionStorage.setItem('howmaBaRequest', JSON.stringify(data))
        if (
          data.real_estate_type === 'condo' &&
          data.report.condo.room_current_state === 'rent'
        ) {
          redirect(`/condo-income-reports/create`)
        } else {
          redirect(`/reports/${data.real_estate_type}/create`)
        }
      } else {
        // 反響情報が取得できない場合はTOPに戻る
        redirect('/')
      }
    }
  }
}
